html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  margin-bottom: 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.2);
  }
}

.modalws {
  width: 100%;
}

.border-image-upload {
  cursor: pointer;
}

.fondo-titulo {
  background-color: rgb(235, 232, 212);
}

.text-autorizacion {
  font-size: 14px;
}