.logo {
  width: 200px;

}

.btn-billetera {
  background-color: rgb(0, 97, 223);
  color: white;
}

.btn-billetera:hover {
  border-color: rgb(0, 97, 223);
  color: rgb(0, 97, 223);
}

.btn-simulador {
  background-color: #77B735;
  color: white;
}

.btn-simulador:hover {
  border-color: #77B735;
  color: #77B735;
  
}

footer {
  margin-top: 50px;
  width: 100%;
  height: 120px;
  background-color: #203B79;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.fondo-azul {
  background-color: #0182c7;
}

.btn-enviar-solicitud {

  font-size: 16px;
}

.nav-ss {
  color: #77B735;

  background-color: rgba(14, 104, 250, 0.094)
}

.seleccion-inicio {
  font-size: 13px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
}

/** input file **/
.img-subir {
  text-align: center;
  border-style: dotted;
  border-color: gray;
  padding-top: 15px;
  padding-bottom: 10px;
}

input[type="file"]#nuestroinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

label[for="nuestroinput"] {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #77B735;
  display: inline-block;
  transition: all .5s;
  cursor: pointer;
  padding: 15px 40px !important;
  text-transform: uppercase;
  width: fit-content;
  text-align: center;
}




.buttons {
  display: flex;
  justify-content: center;
}

.buttons__button {
  width: 180px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #003487;
  margin: 5px 12px;
}

.buttons__button:hover {
  cursor: pointer;
  box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
}

.buttons__button--next {
  background-color: #003487;
  color: #fff
}

.buttons__button--back {
  background-color: #fff;
  color: #003487
}

.error {
  margin-top: 0;
  color: red;
  font-size: 90%;
}


.btnagregarjustificacion {
  width: 180px;

  border-radius: 3px;
  border: 1px solid #77B735;
  background-color: #639e28;
  color: #fff
}

.btnagregarjustificacion:hover {
  cursor: pointer;
  box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
  background-color: #527e25;
  color: #fff
}

.btnsiguiente {
  width: 180px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #003487;
  background-color: #005eb6;
  color: #fff
}

.btnsiguiente:hover {
  cursor: pointer;
  box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
  background-color: #003487;
  color: #fff
}

.color-require {
  color: rgb(252, 14, 14);
}


.btn-salir {
  width: 180px;
  padding: 11px;
  border-radius: 3px;
  border: 1px solid #b36500;
  background-color: #e06500;
  color: #fff;
  padding-left: 80px;
  padding-right: 80px;
  text-decoration: none
}

.btn-salir:hover {
  cursor: pointer;
  box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
  background-color: #eb8500;
  color: #fff
}



ol.stepper {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: rgb(19, 131, 206);
  --active-c: white;
  --circle: 2.3em; /* size of circle */
  --b: 1px; /* line thickness */
  
  display: flex;
  list-style: none;
  justify-content: space-between;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    50% calc((var(--circle) - var(--b))/2)/100% var(--b);
  counter-reset: step;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  font-size: 20px;
  counter-reset: step;
  overflow: hidden;
}
ol.stepper li {
  display: grid;
  place-items: center;
  gap: 5px;
  font-family: sans-serif;
  position: relative;
}
ol.stepper li::before {
  content: counter(step) " ";
  counter-increment: step;
  display: grid;
  place-content: center;
  aspect-ratio: 1;
  height: var(--circle);
  border: 5px solid #fff;
  box-sizing: border-box;
  background: var(--active-b);
  color: var(--active-c);
  border-radius: 50%;
  font-family: monospace;
  z-index: 1;
}
ol.stepper li.active ~ li::before{
  background: var(--default-b);
  color: var(--default-c);
}
ol.stepper li.active::after {
  content: "";
  position: absolute;
  height: var(--b);
  right: 100%;
  top: calc((var(--circle) - var(--b))/2);
  width: 100vw;
  background: var(--active-b);
}

.textwn {
  font-size: 14px;
}