.contenedor-imagen img {
    opacity: 0.75;
}

.contenedor-imagen {
    background-color: rgb(0, 97, 223);
    text-align: center;
    position: relative;
}

.btn-siguiente {
    background-color: rgb(0, 97, 223);
    color: white;
    padding-top: 7px;
    font-size: 17px;
}

.btn-siguiente:hover {
    border-color: rgb(0, 97, 223);
    color: rgb(0, 97, 223);
}

.beneficios {
    background-color: rgb(215, 231, 250);
}

.icons-beneficios {
    font-size: 49px;
    color: #77B735;
}

li {
    margin-bottom: 6px;
    color: #77B735;
}

li span {
    color: rgb(16, 16, 16);
}

.titulo-requisitos {
    color: rgb(0, 97, 223);
}

.colors {
color: #0a48ac;
}

.ico-triste {
    font-size: 70px;
}

.card-fondo {
    background-color: #f3ba005b;
}